//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-732:_2016,_1144,_4476,_4940,_9008,_332,_5932,_1620;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-732')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-732', "_2016,_1144,_4476,_4940,_9008,_332,_5932,_1620");
        }
      }catch (ex) {
        console.error(ex);
      }